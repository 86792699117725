import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { injectIntl } from 'react-intl';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import SectionCustomColumn from '../PageBuilder/SectionBuilder/SectionCustomColumn/SectionCustomColumn';
import SectionCustomHeroOld from '../PageBuilder/SectionBuilder/SectionCustomHero/SectionCustomHeroOld';
import SectionCustomHero from '../PageBuilder/SectionBuilder/SectionCustomHero/SectionCustomHero';

import hero from '../../assets/heroNew.jpg';
import heroLR from '../../assets/heroLR.jpg';

import { GoogleAnalyticsHandler } from '../../analytics/handlers';
import { googleAnalyticsEnabled } from '../../config/configAnalytics';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { inProgress, error, intl } = props;

  useEffect(() => {
    const analyticsHandler = new GoogleAnalyticsHandler();
    if (googleAnalyticsEnabled == true) {
      analyticsHandler.trackCustomEvent('Visit');
    }
  }, []);

  const pageAssetsData = {
    sections: [
      {
        sectionType: 'hero',
        sectionId: 'hero-section',
        svgImage: hero,
        svgImageLR: heroLR,
        title: intl.formatMessage({ id: 'LandingPage.title' }),
        text: intl.formatMessage({ id: 'LandingPage.text' }),
        blocks: [
          {
            blockType: 'defaultBlock',
            blockId: 'button-1',
            callToAction: {
              fieldType: 'internalButtonLink',
              href: '/shop',
              content: intl.formatMessage({ id: 'LandingPage.shopButton' }),
            },
          },
          {
            blockType: 'defaultBlock',
            blockId: 'button-2',
            callToAction: {
              fieldType: 'internalButtonLink',
              href: '/sell',
              content: intl.formatMessage({ id: 'LandingPage.sellButton' }),
            },
          },
        ],
      },
    ],
  };

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData}
      options={{
        sectionComponents: {
          'custom-column': { component: SectionCustomColumn },
          'hero-old': { component: SectionCustomHeroOld },
          hero: { component: SectionCustomHero },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      currentPage={'LandingPage'}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default injectIntl(LandingPage);
